import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { RankState } from '../store/rank/rank-state';
import { Observable } from 'rxjs';
import { Rank } from '../models/rank';
import { GetAllRank } from '../store/rank/rank-actions';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {

  @Select(RankState.ranks) ranks$: Observable<Rank[]>;
  ranks: Rank[];

  constructor(private store: Store) {
    this.ranks$.subscribe( rank => this.ranks=rank);
   }

  ngOnInit(): void {
    this.store.dispatch(new GetAllRank());
  }

}
