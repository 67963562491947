import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Leaderboard } from '../store/donor/donor-actions';
import { Store, Select } from '@ngxs/store';
import { DonorState } from '../store/donor/donor-state';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  @Select(DonorState.leaderboard) leaderboard$:Observable<any>;
  leaderboard:any;
  pageLoaded: boolean;

  constructor(private store: Store) { 
    this.leaderboard$.subscribe(leaderboard => this.leaderboard=leaderboard);

  }

  destroy$= new Subject<void>();
  
  ngOnDestroy():void {
    this.destroy$.next();
 this.destroy$.complete();
}


  ngOnInit(): void {
    this.store.dispatch(new Leaderboard()).subscribe(()=>this.pageLoaded=true);
  }

}
