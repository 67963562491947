import { AgeRangeStateModel } from './age-range-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AgeRangeService } from 'src/app/services/age-range.service';

import { GetAllAgeRange, GetAgeRange, UpdateAgeRange, CreateAgeRange } from './age-range-actions';
import { tap } from 'rxjs/operators';

@State<AgeRangeStateModel>({
    name:'AgeRange'
  })
@Injectable()
  export class AgeRangeState{
  
    constructor(private service: AgeRangeService){
  
    }
  
    @Selector()
    static ageRanges(state:AgeRangeStateModel){
      return state.ageRanges.filter( object => object.active==true);
    }

    @Selector()
    static agerangeMethod(state:AgeRangeStateModel){
      return state.agerange;
    }
  
  
    @Action(GetAllAgeRange)
    getageRanges({patchState}:StateContext<AgeRangeStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          ageRanges: result
        });
      }));
    }

    @Action(GetAgeRange)
    getagerangeMethodById({patchState}:StateContext<AgeRangeStateModel>, {id}: GetAgeRange){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          agerange: result
        });
      }));
    }
  
    @Action(UpdateAgeRange)
    updateagerangeMethod({dispatch}:StateContext<AgeRangeStateModel>,{update}:UpdateAgeRange
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllAgeRange());
            }
          )
        )
      }

      @Action(CreateAgeRange)
      deleteagerange({dispatch, patchState}:StateContext<AgeRangeStateModel>,{create}:CreateAgeRange
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllAgeRange());
              }
            )
          )
        }
  
  
    
  
  }