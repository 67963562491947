<clr-main-container>
    <app-header></app-header>
    <div class="content-container">
        <clr-vertical-nav [clr-nav-level]="2">
            <a clrVerticalNavLink routerLink="/leaderboard" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="user" class="is-solid"></clr-icon>
               Leaderboard
            </a>
            <a clrVerticalNavLink routerLink="/badges" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="crown" class="is-solid"></clr-icon>
                Badges
            </a>
        </clr-vertical-nav>
        <div class="content-area">
            <h1 style="font-weight:bolder;">Badges</h1>
            <p>These are the different ranks you can be awarded as you level up on the Unity.GY platform. Each rank indicates the level of dedication you have put into helping youth in Guyana.
            </p>

            
            <span class="spinner"  style="margin:3%" *ngIf="!(ranks$ | async)">
                Loading...
            </span>


            <table class="table" *ngIf="ranks$ | async">
                <caption>
                    Badges Earnable By Users and Point Requirements
                </caption>
                <thead>
                    <th></th>
                    <th>Name</th>
                    <th>Points Required</th>

                </thead>
                <tbody>
                    <tr *ngFor="let rank of ranks">
                        <td><img src="{{rank.image?.path}}"  style="min-width:100px; max-width:30%; border-radius:50%;height:auto;" alt=""></td>
                        <td>
                            <div class="clr-row clr-align-items-center" style="height:100px">
                                <div class="clr-col">
                                    {{rank.name}}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="clr-row clr-align-items-center" style="height:100px">
                                <div class="clr-col">
                                    {{rank.pointsRequirement}}
                                </div>
                            </div>
                           
                        </td>
                    </tr>

                </tbody>

            </table>
        </div>
    </div>
</clr-main-container>