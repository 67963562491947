import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, fromEvent } from 'rxjs';
import { Task } from '../models/task';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';
import { api } from './api-link';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http:HttpClient) { }

  errorHandl(error:any) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

httpOptions={
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}
private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
get isOnline() {
  return navigator.onLine;
}
  get(id:number):Observable<Task>{
    return this.http.get<Task>(api+"/Task/"+id).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  accept(id:number):Observable<Task>{
    return this.http.post<Task>(api+'/Task/acceptTask',JSON.stringify(id),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  complete(id:number):Observable<Task>{
    return this.http.post<Task>(api+'/Task/completeTask',JSON.stringify(id),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  delete(id: number):Observable<Task>{
    return this.http.post<Task>(api+'/Task/UpdateTask',JSON.stringify({taskId:id, active:false}),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  removeDonor(taskId: number) {
    return this.http.post<Task>(api+'/Task/RemoveDonor',JSON.stringify(taskId),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  getByDonor():Observable<Task[]>{
    return this.http.get<Task[]>(api+"/Task/GetDonorTask").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  getAll():Observable<Task[]>{
    return this.http.get<Task[]>(api+"/Task/").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  update(update: any):Observable<Task>{
    return this.http.post<Task>(api+'/Task/updateTask',JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

    create(create: any):Observable<Task>{
      return this.http.post<Task>(api+'/Task/createTask',JSON.stringify(create),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }



}