<clr-main-container>
    <app-header></app-header>
    <div class="content-container" *ngIf="role$ | async">

        <div class="content-area" style="padding:1%;" *ngIf="role=='Donor'">

            <div class="clr-row mobile" >
                <div class="clr-col-12">
                    <div class="card" *ngIf="donor$ | async">
                        <div class="card-header" *ngIf="user$ | async">
                            <img class="profile" *ngIf="donor.image" src="{{donor.image.path}}">
                            <img class="profile" *ngIf="!donor.image" src="../../assets\blank_profile.webp">
                            {{user.firstName}} {{user.lastName}}
                         </div>
                        <div class="card-block" >

                            <span class="spinner" *ngIf="!donorTasksDone">
                                Loading...
                            </span>

                            <div class="clr-row" *ngIf="donorTasksDone"> 
                                <table style="width:100%;padding:0;margin:0;">
                                    <tr style="width:100%;margin:0;padding:0;">
                                        <td style="text-align: right;"> <div> <img src="{{donor.rank.image?.path}}" style="height:55px;width:55px;">
                                              </div>
                                             </td> 
                                              <td style="text-align: left;"> 
                                                <span> <span style="font-weight:bolder;font-size:20px;">{{donor.rank.name | uppercase}}</span><br>
                                                <span *ngIf="!points">0 points</span> <span *ngIf="points"> {{points}} points</span>
                                              </span></td>
                                      
                                    
                                    </tr>
                                </table> 
                            </div>

                        </div>
                        <div class="card-footer" style="text-align: center;">
                            <div class="btn-group">
                                <button routerLink="/authentication/my-account" class="btn btn-sm btn-primary"> <clr-icon shape="pencil" class="is-solid"></clr-icon> Edit Profile</button>
                                <button routerLink="/tasks" class="btn btn-sm btn-primary"> <clr-icon shape="view-list"></clr-icon> Manage Tasks</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-row clr-align-items-start">
                <div class="clr-col-3">
                    <div style="position:fixed;width:25%;height:100%;">

                        <div class="card side-card" style="min-height:20%;margin:0;margin-bottom:2%;"  *ngIf="donor$ | async">
                            <div class="card-header"  *ngIf="user$ | async">
                                <img class="profile" *ngIf="donor.image" src="{{donor.image.path}}">
                                <img class="profile" *ngIf="!donor.image" src="../../assets\blank_profile.webp">
                                {{user.firstName}} {{user.lastName}}
                             </div>
                            <div class="card-block">

                                <span class="spinner" *ngIf="!donorTasksDone">
                                    Loading...
                                </span>
                            <div class="clr-row"  *ngIf="donorTasksDone"> 
                                
                                <table style="width:100%;padding:0;margin:0;">
                                    <tr style="width:100%;margin:0;padding:0;">
                                        <td style="text-align: right;"> <div> <img src="{{donor.rank.image?.path}}" style="height:55px;width:55px;">
                                              </div>
                                             </td> 
                                              <td style="text-align: left;"> 
                                                <span> <span style="font-weight:bolder;font-size:20px;">{{donor.rank.name | uppercase}}</span><br>
                                                <span *ngIf="!points">0 points</span> <span *ngIf="points"> {{points}} points</span>
                                              </span></td>
                                      
                                    
                                    </tr>
                                </table> 
                                
                        
                                 
                            </div>
                        </div>
                            <div class="card-footer" style="text-align: center;">
                                <button routerLink="/authentication/my-account" class="btn btn-sm btn-primary"><clr-icon shape="pencil" class="is-solid"></clr-icon> Edit Profile</button>
                            </div>
                        </div>

                        
                    
                        <div class="card side-card" style="height:30%;margin:0;margin-bottom:2%;" >
                            <div class="card-header" style="background-color:#28536B;color:whitesmoke;">
                                Ongoing Requests
                             </div>

                            <div class="card-block" style="height:50%;overflow: scroll;overflow-x: hidden;">
                                <span class="spinner" *ngIf="!donorTasksDone">
                                    Loading...
                                </span>
                                <div *ngIf="donorTasksDone && tasks$ | async">
                                    <table *ngIf="tasks.length"  style="display: inline-block; overflow: auto;">
                                        <tr *ngFor="let task of tasks">
                                            <td style="padding:10px;"><b>{{task.description}}</b></td><td>{{task.creationDate | date}}</td>
                                        </tr>
                                    </table>
                                </div>
                                
                                <div *ngIf="tasks$ | async">
                                    <h6 *ngIf="donorTasksDone && !tasks.length">No requests in progress at the moment. Accept a request to get started!</h6>
                                </div>
                                
                            </div>
                            <div class="card-footer" style="text-align: center;">
                                <button class="btn btn-primary btn-sm" routerLink="/tasks"><clr-icon shape="view-list"></clr-icon>Manage Requests</button>
                            </div>
                        </div>

                        <div class="card side-card" style="height:30%;margin:0;overflow: hidden;" >
                            <div class="card-header" style="background-color:#28536B;color:whitesmoke;">
                                Recently Completed Requests
                             </div>

                            <div class="card-block" style="height:80%;overflow: scroll;overflow-x: hidden;">
                                
                                <span class="spinner" *ngIf="!allTasksDone">
                                    Loading...
                                </span>
                                <table *ngIf="allTasksDone" style="display: inline-block; overflow: auto;width:100%;">
                                    <tr *ngFor="let task of completedTasks | slice:0:5">
                                        <td style="padding:10px;"><b>{{task.description}}</b> </td>
                                        <td>{{task.completionDate | date}}</td>
                                    </tr>
                                </table>
                            </div>
                           
                        </div>
                    </div>

                    

                   
                    
                </div>

                <div class="clr-col-12 clr-col-lg-6 clr-col-xl-6" style="text-align:center;">

                  
                

                    <span class="spinner" *ngIf="!donorTasksDone">
                        Loading...
                    </span>

                    


                    <div *ngIf="donorTasksDone && (donorTasks$ | async)" >

                        <div *ngIf="donorTasks.length">

                            <h3 style="text-align:center;">Pending Requests</h3> <br>
                            
                        <div class="card post-card"  *ngFor="let task of donorTasks">
                            <div class="card-header">
                               
                                <div *ngIf="!task.referral">
                                    <clr-signpost>
                                        <clr-signpost-content *clrIfOpen>
                                            <h3>About {{task.donee.user.firstName}}</h3> <br>
                                            <b>Description:</b> <span *ngIf="task.donee.personalDescription">{{task.donee.personalDescription}}</span> <br>
                                            <b>Interest Field:</b> <span *ngIf="task.donee.interestField">{{task.donee.interestField.name}}</span> <br>
                                            <b>Income Level:</b> <span *ngIf="task.donee.incomeLevel">{{task.donee.incomeLevel.name}}</span> 
                                        </clr-signpost-content>
                                    </clr-signpost>
                                    {{task.donee.user.firstName}} {{task.donee.user.lastName}} - <span *ngIf="task.donee.sex">{{task.donee.sex}},</span>{{task.donee.user.dob | age }}<span *ngIf="task.donee.deliveryAddress">,{{task.donee.deliveryAddress.village.name}}</span>
                                </div>
                               
                                <div *ngIf="task.referral">
                                    <clr-signpost>
                                        <clr-signpost-content *clrIfOpen>
                                            <h3>About  {{task.referral.firstName}}</h3> <br>
                                            <b>Description:</b> <span *ngIf="task.referral.personalDescription">{{task.referral.personalDescription}}</span> <br>
                                            <b>Interest Field:</b> <span *ngIf="task.referral.interestField"> {{task.referral.interestField.name}}</span> <br>
                                            <b>Income Level:</b> <span *ngIf="task.referral.incomeLevel">{{task.referral.incomeLevel.name}}</span> 
                                        </clr-signpost-content>
                                    </clr-signpost>
                                    {{task.referral.firstName}} {{task.referral.lastName}} - <span *ngIf="task.referral.sex">{{task.referral.sex}},</span>{{task.referral.dob | age }}<span *ngIf="task.referral.deliveryAddress">,{{task.referral.deliveryAddress.village.name}}</span> 
                                </div>
                            </div>
                            <div class="card-block" style="text-align: center;">
                                <table class="center">
                                    <tr style="text-align: center;"><td style="width:50%;">Type:</td><td>{{task.taskType.name}}</td></tr>
                                    <tr style="text-align: center;"><td>Cost (GYD):</td><td style="text-align: center;">{{task.cost}}</td></tr>
                                    <tr style="text-align: center;"><td> Requesting Individual:</td><td style="text-align: center;">{{task.donee.user.firstName}} {{task.donee.user.lastName}}</td></tr>
                                    <tr style="text-align: center;"><td>Description:</td><td style="text-align: center;">{{task.description}}</td></tr>
                                </table>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-sm btn-success" (click)="acceptTask(task.taskId,task.donee.doneeId)"><clr-icon class="is-solid" shape="check"></clr-icon> Accept</button>
                            </div>
                        </div>
                        
                        </div>


                        <div *ngIf="!(donorTasks.length)" style="text-align:center;" >
                         <h3>No Available Requests</h3> 
                        </div>

                        
                    </div>

                   
                </div>

                <div class="clr-col-3" >
                    <div style="position:fixed;width:24%;height:100%;">
                        <div class="card side-card leaderboard" style="height:85%;margin:0;overflow:scroll;overflow-x: hidden;">
                            <div class="card-header">    
                               Our Top Donors
                             </div>

                            <div class="card-block" style="height:70%;">
                                <table style="width:100%;" *ngIf="leaderboard$ | async">
                                   
                                    <tr  *ngFor="let person of leaderboard | slice:0:10;"><td style="padding:5px;"> <img src="../../../assets/blank_profile.webp" *ngIf="!person.profilePicture" style="height:50px;width:50px;border-radius: 50%;" alt=""> <img  *ngIf="person.profilePicture" src="{{person.profilePicture}}" style="height:50px;width:50px;border-radius:50%;"> {{person.firstName}} {{person.lastName}}</td> <td><img src="{{person.badge}}"  style="height:30px;width:30px;" alt="">{{person.totalPoints}} points</td></tr>
                                </table>
                                
                                
                    <span class="spinner" *ngIf="!(leaderboard$ | async)">
                        Loading...
                    </span>

                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

        
     

        </div>

        <div class="content-area" style="padding:1%;" *ngIf="role=='Donee'">

            <div class="clr-row mobile" >
                <div class="clr-col-12">
                    <div class="card" *ngIf="donee$ | async">
                        <div class="card-header" *ngIf="user$ | async">
                            <img class="profile" src="../../assets/blank_profile.webp">
                            {{user.firstName}} {{user.lastName}}
                         </div>
                         <div class="card-block">

                            <div class="clr-row" >   <table>
                            
                                <td style="padding:10px;"> {{donee.personalDescription}}</td>
                            </table>  
                            </div>

                        </div>
                        <div class="card-footer" style="text-align: center;">
                            <div class="btn-group">
                                <button class="btn btn-sm btn-primary" routerLink="/authentication/my-account"><clr-icon shape="pencil" class="is-solid"></clr-icon> Edit Profile</button>
                                <button class="btn btn-sm btn-primary" routerLink="/tasks"><clr-icon shape="view-list"></clr-icon> Manage Requests</button>
                            </div>
                       
                         
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-row clr-align-items-start">
                <div class="clr-col-3">
                    <div style="position:fixed;width:25%;height:100%;">

                        <div class="card side-card" style="min-height:20%;margin:0;margin-bottom:2%;"  *ngIf="donee$ | async">
                            <div class="card-header"  *ngIf="user$ | async">
                                <img class="profile" src="../../assets/blank_profile.webp">
                                {{user.firstName}} {{user.lastName}}
                             </div>
                            <div class="card-block">

                                <div class="clr-row" >   <table>
                                
                                    <td style="padding:10px;"> {{donee.personalDescription}}</td>
                                </table>  
                                </div>
    
                            </div>
                            <div class="card-footer" style="text-align: center;">
                                <div class="btn-group">
                                    <button class="btn btn-sm btn-primary" routerLink="/authentication/my-account"><clr-icon shape="pencil" class="is-solid"></clr-icon> Edit Profile</button>
                                </div>
                            </div>
                        </div>

                        
                    
                        <div class="card side-card" style="height:30%;margin:0;margin-bottom:2%;overflow: hidden;">
                            <div class="card-header" style="background-color:#28536B;color:whitesmoke;">
                                Pending Requests
                             </div>

                            <div class="card-block" style="height:80%;overflow: scroll;overflow-x: hidden;">
                                
                                <span class="spinner" *ngIf="!allTasksDone">
                                    Loading...
                                </span>
                                <table  *ngIf="allTasksDone && pendingTasks.length" style="display: inline-block; overflow: auto;width:100%;">
                                    <tr *ngFor="let task of pendingTasks">
                                        <td style="padding:10px;"><b>{{task.description}}</b></td>
                                        <td>{{task.creationDate | date}}</td>
                                        <td><button class="btn btn-sm btn-primary" (click)="editTask(task.taskId)"> Edit</button></td>
                                    </tr>
                                 
                                </table>
                                <div *ngIf="pendingTasks$ | async">
                                    <h6 *ngIf="allTasksDone && !pendingTasks.length">No pending requests at the moment. <a routerLink="/tasks/create-task">Create a request</a> to get started!</h6>
                                </div>
                            </div>
                        </div>

                        <div class="card side-card" style="height:30%;margin:0;overflow: hidden;">
                            <div class="card-header" style="background-color:#28536B;color:whitesmoke;">
                                Recently Completed Requests
                             </div>

                            <div class="card-block" style="height:80%;overflow: scroll;overflow-x: hidden;">

                                
                                <span class="spinner" *ngIf="!allTasksDone">
                                    Loading...
                                </span>

                                <table  *ngIf="allTasksDone" style="display: inline-block; overflow: auto;width:100%;">
                                    <tr *ngFor="let task of completedTasks">
                                        <td style="padding:10px;"><b>{{task.description}}</b></td>
                                        <td>{{task.completionDate | date}}</td>
                                    </tr>
                                </table>
    
                            </div>
                        </div>
                    </div>

                    

                   
                    
                </div>

                <div class="clr-col-12 clr-col-lg-6 clr-col-xl-6" style="text-align:center;">

                  
                

                    <span class="spinner" *ngIf="!allTasksDone">
                        Loading...
                    </span>

                    


                    <div *ngIf="allTasksDone" >

                        <div *ngIf="tasks.length">

                            <h3 style="text-align:center;">In-Progress Requests</h3> <br>
                            
                        <div class="card post-card" *ngFor="let task of tasks" >
                            <div class="card-header">
                                <span *ngIf="!task.referral"> {{task.donee.user.firstName}} {{task.donee.user.lastName}}</span> 
                                <span *ngIf="task.referral">  {{task.referral.firstName}} {{task.referral.lastName}}</span>
                            </div>
                            <div class="card-block">
                                <table class="center">
                                    <tr style="text-align: center;"><td >Type:</td><td style="text-align: center;">{{task.taskType.name}}</td></tr>
                                    <tr style="text-align: center;"><td  >Cost(GYD):</td><td style="text-align: center;">{{task.cost}} GYD</td></tr>
                                    <tr style="text-align: center;"><td   >Description:</td><td style="text-align: center;">{{task.description}}</td></tr>
                                    <tr style="text-align: center;"><td >Donor:</td><td *ngIf="task.donor.user" style="text-align: center;">{{task.donor.user.firstName}} {{task.donor.user.lastName}}</td></tr> 
                                </table>
                            </div>
                            <div class="card-footer" style="text-align: center;">

                                <div class="btn-group">
                                    <button class="btn btn-sm btn-primary" (click)="editTask(task.taskId)"><clr-icon shape="pencil"></clr-icon> Edit</button>
                                    <button class="btn btn-sm btn-primary" (click)="sendMessage(task.donorId,task.donor.user.firstName+' '+task.donor.user.lastName)"><clr-icon shape="envelope"></clr-icon> Contact Donor</button>
                                    <button class="btn btn-sm btn-success" (click)="markComplete(task.taskId,task.donor.donorId)"><clr-icon class="is-solid" shape="check"></clr-icon> Finish</button>
                                   
                                </div>
                               
                            </div>
                        </div>
                        
                        </div>


                        <div *ngIf="!(tasks.length)" style="text-align:center;" >
                         <h3>No Requests In Progress</h3> 
                        </div>

                        
                    </div>

                   
                </div>

                <div class="clr-col-3" >
                    <div style="position:fixed;width:24%;height:100%;">
                        <div class="card side-card leaderboard" style="height:85%;margin:0;overflow:scroll;overflow-x: hidden;">
                            <div class="card-header">    
                               Our Top Donors
                             </div>

                            <div class="card-block">
                                <table *ngIf="leaderboard$ | async" style="width:100%;">
                                   
                                    <tr  *ngFor="let person of leaderboard | slice:0:10;"><td style="padding:5px;"> <img src="../../../assets/blank_profile.webp" *ngIf="!person.profilePicture" style="height:50px;width:50px;border-radius: 50%;" alt=""> <img  *ngIf="person.profilePicture" src="{{person.profilePicture}}" style="height:50px;width:50px;border-radius:50%;"> {{person.firstName}} {{person.lastName}}</td> <td><img src="{{person.badge}}"  style="height:30px;width:30px;" alt="">{{person.totalPoints}} points</td></tr>
                                </table>
                                
                                
                    <span class="spinner" *ngIf="!(leaderboard$ | async)">
                        Loading...
                    </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <clr-modal >
                <h3 class="modal-title">Confirmation</h3>
                <div class="modal-body">
                    <p>Are you sure you're up to this challenge?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" >Cancel</button>
                    <button type="button" class="btn btn-success">Yes</button>
                </div>
            </clr-modal>
     

        </div>





        <div class="content-area" style="padding:1%;" *ngIf="role=='Admin'">
            <div class="clr-row">
                <div class="clr-col-lg-3"  *ngFor="let pending of users">
                    <div class="card">
                        <div class="card-title" style="font-size:14px; font-weight: bold;">Approved: {{pending.approved}} <br> Active: {{pending.active}} <br> {{pending.firstName}} {{pending.middleName}} {{pending.lastName}} - {{pending.dob | date}} <br>Role: {{pending.role.name}}</div>
                        <div class="card-block">
                            <img *ngIf="pending.image" src="{{pending.image.path}}"  style="width:auto; height:200px;border-radius: 5%;" alt="">
                            <img *ngIf="!pending.image" src="../../assets/blank_profile.webp" alt="" style="width:auto; height:200px;border-radius: 5%;">
                        
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-success" (click)="confirm('approveUser',pending.userId)"> Approve</button>
                            <button class="btn btn-danger" (click)="confirm('deleteUser',pending.userId)"> Delete</button>
                        </div>
                    </div>
                </div>
            </div>
           


            </div>


    </div>
    
    <clr-modal [(clrModalOpen)]="confirmationModal" [clrModalClosable]="false" [clrModalSize]="'md'">
        <h3 class="modal-title">Confirmation</h3>
        <div class="modal-body">
            {{confirmationMessage}}
        </div>
        
        <div class="modal-footer">
            <button class="btn btn-success" (click)="performAction()" *ngIf="!loading">Yes</button>
            <button class="btn btn-danger" (click)="confirmationModal=false" *ngIf="!loading">Cancel</button>
        
            <div *ngIf="loading">
              <span class="spinner spinner-inline">
                Loading...
            </span>
            <span>
                Loading...
            </span>
            </div>
        
        
        </div>
        </clr-modal>
        
          
        
          <clr-modal [(clrModalOpen)]="(!success || !failure) && this.statusModal"  [clrModalClosable]="false">
            <h3 class="modal-title">Action Status</h3>
            <div class="modal-body">
                <div class="clr-row">
                    <div class="clr-col">
                            <clr-alert [clrAlertType]="'success'" [clrAlertClosable]="false" [(clrAlertClosed)]='success'>
                                    <clr-alert-item>
                                        <span class="alert-text">
                                            Action completed successfully.
                                        </span>
                                    </clr-alert-item>
                                </clr-alert>
                                <clr-alert [clrAlertType]="'danger'" [(clrAlertClosed)]='failure'>
                                        <clr-alert-item>
                                            <span class="alert-text">
                                                An error occured! Check internet connection.
                                            </span>
                                        </clr-alert-item>
                                    </clr-alert>
                    </div>
                </div>                
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary" (click)="statusModal=false"> Ok</button>
            </div>
            </clr-modal>
</clr-main-container>

