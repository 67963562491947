<clr-main-container>
    <app-header></app-header>
    <div class="content-container">
       
        <div class="content-area">
            <div class="clr-col-lg-12" style="text-align: justify;">
                <h1 style="font-weight:bolder;">Guide</h1>
                <hr>
                <h2 style="font-weight:bolder;">How To Use Unity</h2>
                <p>The process for giving and receiving assistance on Unity.GY is outlined below.</p>
                <clr-accordion>
                    <clr-accordion-panel>
                      <clr-accordion-title> Donee Process</clr-accordion-title>
                      <clr-accordion-content *clrIfExpanded>
                            
                <ol>
                    <p><li>Go to the <b> 'Requests'</b> page from the menu and navigate to the <b>'Create Request'</b> page.</li></p>
                    <p><li>Fill out the relevant request information and submit the form.</li></p>
                    <p><li>Wait until a donor has accepted your request. You will be notified of this in-app and via email.</li></p>
                    <p><li>Visit the timeline and click the <b>'Message Donor'</b>  button on the request to contact the donor. 
                      <br>  <b>OR</b> <br>
                        Click the <b>'View & Edit'</b> button on the relevant request from the <b>'Requests'</b>  page to enter edit view. 
                        From the edit view,  click <b>'Message Donor'</b>  to send a message.
                    </li></p>
                    <p><li>Type and submit the message. They will be notified of your message.</li></p>
                    <p><li>When the donor has responded to you and has successfully completed the request, mark the request completed from your timeline or edit request page.
                        <br> <b>OR</b> <br>
                    If the donor cannot complete the request, click the <b>'View & Edit'</b>  button on relevant request from the request page then click <b>'Remove Donor'</b>.
                    </li></p>
                    
                </ol>
               
                      </clr-accordion-content>
                    </clr-accordion-panel>
                  
                    <clr-accordion-panel>
                      <clr-accordion-title> Donor Process</clr-accordion-title>
                      <clr-accordion-content *clrIfExpanded>
                           
                <ol>
                    <p><li>Upon logging in, you will see your timeline with your requests. Select requests that interest you that appear there by clicking the <b>'Accept'</b> button.</li></p>
                    <p><li>After accepting the request, the donee will be notified. <b>Wait for them to message you or reach out to them.</b> </li></p>
                    <p><li>To message, go to the <b>'Requests'</b>  page and view your list of requests. Click the <b>'Contact'</b> button on the relevant request to send a message to the donee.
                       <br> <b>OR</b><br>
                        To deliver an item, go to the <b>'Requests'</b>  page and view your list of requests. Click the <b>'Deliver To'</b> button on the relevant request to send a delivery to the donee .
                    </li></p>
                    <p><li> Fill out the form and submit. They will be notified of your message/delivery.
                    </li></p>
                    <p><li> <b>IF YOU SCHEDULED A DELIVERY</b>, you will be called from the delivery service and you can make arrangements with them. <br> When you receive the delivery <b>MARK IT RECEIVED</b> by pressing the button on the relevent delivery on <b>'Delivery'</b> page.</li></p>
                    <p><li>When you have successfully completed the request, <b>remind the donee to mark the request completed</b>.</li></p>
                    <p><li>The points from the completed request will go to your total score!</li></p>
                </ol>
    
                      </clr-accordion-content>
                    </clr-accordion-panel>
                  
                  </clr-accordion>

              
              

              
                <h2 style="font-weight:bolder">Rules</h2>
                <p>Unity.GY hopes to create a safe space for donors and donees. Please follow our rules to protect yourself and other users.
                </p>

                <clr-accordion>
                    <clr-accordion-panel>
                      <clr-accordion-title>Rule 1: Do not engage in any discriminatory practices</clr-accordion-title>
                      <clr-accordion-content *clrIfExpanded>Unity.GY is a platform that is inclusive to all races, genders and sexual orientations. <b>DO NOT</b>  use the platform as a means to discriminate against
                        <b>ANY</b> user.</clr-accordion-content>
                    </clr-accordion-panel>
                  
                    <clr-accordion-panel>
                      <clr-accordion-title>Rule 2: No foul or abusive language/behaviour</clr-accordion-title>
                      <clr-accordion-content *clrIfExpanded>Unity.GY is a platform that intends to be  <b>child-friendly and safe</b>  for all users. Do not harm other users in any way, <em>whether verbally, physically, emotionally</em>.
                      </clr-accordion-content>
                    </clr-accordion-panel>
                  
                    <clr-accordion-panel>
                      <clr-accordion-title>Rule 3: Be cautious when establishing connections</clr-accordion-title>
                      <clr-accordion-content *clrIfExpanded>Both donors and donees should <b>ENSURE</b> that they are in contact with safe, trustworthy users before making any transactions. <br> <b>DO NOT</b> provide other users with information that could result in harm being done to you.
                      </clr-accordion-content>
                    </clr-accordion-panel>

                    <clr-accordion-panel>
                        <clr-accordion-title>Rule 4: Be honest in creating and marking requests complete</clr-accordion-title>
                        <clr-accordion-content *clrIfExpanded>
                            Unity.GY will <em>continuously screen </em>the request history of our donors when noticing suspicious activity. This is towards maintaining a clean and accurate dataset.
                 <b>BE HONEST</b>  in the creation and execution of requests.
                        </clr-accordion-content>
                      </clr-accordion-panel>

                      <clr-accordion-panel>
                        <clr-accordion-title>Rule 5: Be intentional in the scheduling of deliveries</clr-accordion-title>
                        <clr-accordion-content *clrIfExpanded>
                            Make sure all deliveries scheduled through the platform are <b> INTENDED TO BE DELIVERED</b>. Unity.GY is in partnership with a third-party delivery service and intends to refer <em>legitimate customers</em>. Every account that intentionally carries out a false delivery <b>WILL BE IMMEDIATELY REMOVED.</b> 
                        </clr-accordion-content>
                      </clr-accordion-panel>
                  </clr-accordion>


              
            </div>
           
        </div>
    </div>
</clr-main-container>