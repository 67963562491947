export class GetRegion{
    constructor(public id: number){
    
    }
    
    static readonly type="[Region] GetRegion";
    
    }
    
    export class GetAllRegion{
        constructor(){
        
        }
        
        static readonly type="[Region] GetAllRegion";
        
        }
    
    export class UpdateRegion{
        constructor(public update:any){
    
        }
    
        static readonly type="[Region] UpdateRegion";
    }
        
    export class CreateRegion{
        constructor(public create:any){
    
        }
    
        static readonly type="[Region] CreateRegion";
    }


