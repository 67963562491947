export class GetTask{
    constructor(public id: number){
    
    }
    
    static readonly type="[Task] GetTask";
    
    }

    export class AcceptTask{
        constructor(public id:number){

        }
        static readonly type="[Task] AcceptTask";
    }

    export class SendToCDC{
        constructor(public id:any){

        }
        static readonly type="[Task] SendToCDC";
    }


    export class CompleteTask{
        constructor(public id:number){

        }
        static readonly type="[Task] CompleteTask";
    }

    export class DeleteTask{
        constructor(public id:number){

        }
        static readonly type="[Task] DeleteTask";
    }

    export class GetDonorTask{
        constructor(){
        
        }
        
        static readonly type="[Task] GetDonorTask";
        
        }
    
    export class GetAllTask{
        constructor(){
        
        }
        
        static readonly type="[Task] GetAllTask";
        
        }
    
    export class UpdateTask{
        constructor(public update:any){
    
        }
    
        static readonly type="[Task] UpdateTask";
    }
        
    export class CreateTask{
        constructor(public create:any){
    
        }
    
        static readonly type="[Task] CreateTask";
    }


    export class RemoveTaskDonor{
        constructor (public taskId: number){}

        static readonly type="[Task] RemoveDonor";
    }

