<clr-main-container>
    <app-header></app-header>
    <div class="content-container" >
        <clr-vertical-nav [clr-nav-level]="2">
            <a clrVerticalNavLink routerLink="/delivery/my-deliveries" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="store" class="is-solid"></clr-icon>
                My Deliveries
            </a>

        </clr-vertical-nav>
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>
