export class GetDelivery{
    constructor(public id: number){
    
    }
    
    static readonly type="[Delivery] GetDelivery";
    
    }
    
    export class GetAllDelivery{
        constructor(){
        
        }
        
        static readonly type="[Delivery] GetAllDelivery";
        
        }
    
    export class UpdateDelivery{
        constructor(public update:any){
    
        }
    
        static readonly type="[Delivery] UpdateDelivery";
    }
        
    export class CreateDelivery{
        constructor(public create:any){
    
        }
    
        static readonly type="[Delivery] CreateDelivery";
    }


