export class GetInterestField{
    constructor(public id: number){
    
    }
    
    static readonly type="[InterestField] GetInterestField";
    
    }
    
    export class GetAllInterestField{
        constructor(){
        
        }
        
        static readonly type="[InterestField] GetAllInterestField";
        
        }
    
    export class UpdateInterestField{
        constructor(public update:any){
    
        }
    
        static readonly type="[InterestField] UpdateInterestField";
    }
        
    export class CreateInterestField{
        constructor(public create:any){
    
        }
    
        static readonly type="[InterestField] CreateInterestField";
    }


