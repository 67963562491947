export class Signin {
    static readonly type = '[Authentication] Signin';
  
    constructor(public payload:{username:string, password:string}) {
  
    }
  }
  
  export class Signout {
    static readonly type = '[Authentication] Signout';
  
    constructor(){
      
    }
  }
  
  
  export class PersistLocal{
    static readonly type='[Authentication] PersistLocal';
  
    constructor(public payload:{token: string, authorization:string, id:number}){

    }
  }

  export class ResetPassword{

    static readonly type='[Authentication] ResetPassword';

    constructor(public payload: any){

    }

  }


  export class VerifyCode{

    static readonly type='[Authentication] VerifyPassword';

    constructor(public payload: any){
      
    }

  }


  export class LoginError{
    static readonly type='[Authentication] LoginError';

    constructor(public string: string){
      
    }
  }

  export class CheckEmail{
    static readonly type='[Authentication] CheckEmail';

    constructor(public email: string){

    }
  }

  export class CheckUsername{
    static readonly type='[Authentication] CheckUsername';

    constructor(public username: string){
      
    }


  }

  export class ResendVerify{
    static readonly type="[Authentication] Resend";
    constructor (public email:string){}
  }