import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, fromEvent } from 'rxjs';
import { Role } from '../models/role';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';
import { api } from './api-link';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private http:HttpClient) { }

  errorHandl(error:any) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

httpOptions={
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}

private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
get isOnline() {
  return navigator.onLine;
}

  get(id:number):Observable<Role>{
    return this.http.get<Role>(api+"/Role/"+id).pipe(
      retry(1),
      catchError(this.errorHandl)
    )

  }

  getAll():Observable<Role[]>{
    return this.http.get<Role[]>(api+"/Role/").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  update(update: any):Observable<Role>{
    return this.http.post<Role>(api+'/Role/updateRole',JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

    create(create: any):Observable<Role>{
      return this.http.post<Role>(api+'/Role/createRole',JSON.stringify(create),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }



}