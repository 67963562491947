import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(dob: Date): unknown {
    var diff_ms = Date.now() - new Date(dob).getTime();
    var age_dt = new Date(diff_ms); 

     return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

}
