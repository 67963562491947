import { IncomeLevelStateModel } from './income-level-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { IncomeLevelService } from 'src/app/services/income-level.service';

import { GetAllIncomeLevel, GetIncomeLevel, UpdateIncomeLevel, CreateIncomeLevel } from './income-level-actions';
import { tap } from 'rxjs/operators';

@State<IncomeLevelStateModel>({
    name:'IncomeLevel'
  })
@Injectable()
  export class IncomeLevelState{
  
    constructor(private service: IncomeLevelService){
  
    }
  
    @Selector()
    static incomeLevels(state:IncomeLevelStateModel){
      return state.incomeLevels.filter( object => object.active==true);
    }

    @Selector()
    static incomelevelMethod(state:IncomeLevelStateModel){
      return state.incomelevel;
    }
  
  
    @Action(GetAllIncomeLevel)
    getincomeLevels({patchState}:StateContext<IncomeLevelStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          incomeLevels: result
        });
      }));
    }

    @Action(GetIncomeLevel)
    getincomelevelMethodById({patchState}:StateContext<IncomeLevelStateModel>, {id}: GetIncomeLevel){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          incomelevel: result
        });
      }));
    }
  
    @Action(UpdateIncomeLevel)
    updateincomelevelMethod({dispatch}:StateContext<IncomeLevelStateModel>,{update}:UpdateIncomeLevel
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllIncomeLevel());
            }
          )
        )
      }

      @Action(CreateIncomeLevel)
      deleteincomelevel({dispatch, patchState}:StateContext<IncomeLevelStateModel>,{create}:CreateIncomeLevel
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllIncomeLevel());
              }
            )
          )
        }
  
  
    
  
  }