<clr-main-container>
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row clr-justify-content-center" style="min-height:100%;">
                <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-7 clr-align-self-center">
                    <div class="clr-col" style="text-align:center;">
                     
                    </div>
                   

                    <div class="card">
                        <div class="card-block">
                            <div class="clr-col" style="text-align:center;">

                                <div class="clr-row clr-justify-content-center">

                                    <img src="../../../assets/GY-unity.png" class="logo">
                                    
                                    <div class="clr-break-row"></div>
                               
                   
                                     <h2 style="font-weight: bold;">Delivery Accept Failed</h2>
                                     <p>You have <b>failed to accept</b> the delivery from the user. This delivery no longer exists or has been previously accepted.</p> 
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>  
            </div>
        </div>
    </div>
</clr-main-container>
