import { RankStateModel } from './rank-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RankService } from 'src/app/services/rank.service';

import { GetAllRank, GetRank, UpdateRank, CreateRank } from './rank-actions';
import { tap } from 'rxjs/operators';

@State<RankStateModel>({
    name:'Rank'
  })
@Injectable()
  export class RankState{
  
    constructor(private service: RankService){
  
    }
  
    @Selector()
    static ranks(state:RankStateModel){
      return state.ranks.filter( object => object.active==true);
    }

    @Selector()
    static rankMethod(state:RankStateModel){
      return state.rank;
    }
  
  
    @Action(GetAllRank)
    getranks({patchState}:StateContext<RankStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          ranks: result
        });
      }));
    }

    @Action(GetRank)
    getrankMethodById({patchState}:StateContext<RankStateModel>, {id}: GetRank){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          rank: result
        });
      }));
    }
  
    @Action(UpdateRank)
    updaterankMethod({dispatch}:StateContext<RankStateModel>,{update}:UpdateRank
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllRank());
            }
          )
        )
      }

      @Action(CreateRank)
      deleterank({dispatch, patchState}:StateContext<RankStateModel>,{create}:CreateRank
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllRank());
              }
            )
          )
        }
  
  
    
  
  }