import { AgeRangeState } from './age-range/age-range-state';
import { AuthenticationState } from './authentication/authentication-state';
import { DifficultyState } from './difficulty/difficulty-state';
import { DoneeState } from './donee/donee-state';
import { DonorState } from './donor/donor-state';
import { ImageState } from './image/image-state';
import { DonorTypeState } from './donortype/donor-type-state';
import { RoleState } from './role/role-state';
import { UserState } from './user/user-state';
import { TaskState } from './task/task-state';
import { VillageState } from './village/village-state';
import { RegionState } from './region/region-state';
import { TaskTypeState } from './task-type/task-type-state';
import { ReferralState } from './referral/referral-state';
import { RankState } from './rank/rank-state';
import { MessageState } from './message/message-state';
import { InterestFieldState } from './interest-field/interest-field-state';
import { IncomeRangeState } from './income-range/income-range-state';
import { IncomeLevelState } from './income-level/income-level-state';
import { DeliveryPersonState } from './delivery-person/delivery-person-state';
import { DeliveryState } from './delivery/delivery-state';
import { AddressState } from './address/address-state';

export const unityState =[
AgeRangeState,
AuthenticationState,
DifficultyState,
DoneeState,
DonorState,
ImageState,
DonorTypeState,
RoleState,
UserState,
TaskState,
VillageState,
RegionState,
TaskTypeState,
ReferralState,
RankState,
MessageState,
InterestFieldState,
IncomeRangeState,
IncomeLevelState,
DeliveryPersonState,
AddressState,
DonorTypeState,
DeliveryState
];

