<clr-main-container>
    <clr-header style="background-color:#28536B;">
        <div class="branding" style="text-align:center;">

            <a href="..." routerLink="/home" class="nav-link">
                <img src="../../assets/GY-unity.png" style="height: inherit;width:auto;">

            </a>
            <span class="title" style="color: #00A140;font-weight:bold;">UNITY<b
                    style="color:#F7CE00;font-weight:bolder;">.GY</b></span>

        </div>

        <div class="header-nav" [clr-nav-level]="1">
            <a href="..." class="nav-link" routerLink="/home" routerLinkActive="active"><span
                    class="nav-text">Home</span></a>
        </div>
        <div class="header-actions">
            <a href=".." routerLink="/authentication/sign-up" class="nav-link nav-icon-text">

                <span class="nav-text">Join Now</span>
            </a>
            <a href=".." routerLink="/authentication/login" class="nav-link nav-icon-text">
                <clr-icon shape="login"></clr-icon>
                <span class="nav-text">Login</span>
            </a>
        </div>

    </clr-header>






    <div class="content-container">

        <div class="content-area" style="padding:0;background-color:whitesmoke;">


            <div class="clr-row clr-justify-content-center"
                style="padding:2%;height:100%;background-size: cover;background-repeat: no-repeat; background-image: url('../../assets/other help.png');">
                <div class="clr-col-lg-7 clr-align-self-center" style="text-align:center;">
                    <h1 style="font-weight:bolder;color:white;font-size:50px;">Collaborating For A Better Guyana</h1>
                    <br>

                    <p style="color:white;font-size: 20px;">Unity.GY is a donor platform created with the intention of
                        <b>connecting</b> marginalized <b>youth</b> with a compatible person who can address their
                        <b>unique needs</b>. Our goal is to create an environment that <b>encourages</b> people to
                        donate towards a <b>fair, happy Guyana</b>.
                    </p>
                    <br>
                    <button class="btn" style="color:black;border-color:white;opacity: 0.7;" (click)="signup()">Get
                        Started</button>
                </div>

            </div>
            <div class="hide-on-init clr-row clr-justify-content-center" animateOnScroll
                animationName="animated slideInUp" style="background-color:white;">
                <div class="clr-col-lg-4 clr-col-12" style="padding:5%;text-align:center;">
                    <img src="../../assets/donate.png" style="width:90%;margin:5%;height:auto;">
                </div>
                <div class="clr-col-lg-5 clr-col-12 clr-align-self-center" style="padding:5%;text-align:center;">
                    <h1 style="font-weight: 500;font-size:50px;">Change lives for the better</h1>
                    <h3>Find those special persons whose lives you can impact with a donation</h3><br>
                    <button class="btn" style="color:black;border-color:black;" (click)="signup()">Start
                        Helping</button>
                </div>
                <div class="clr-break-row"></div>
            </div>

            <div animateOnScroll animationName="animated slideInUp"
                class="hide-on-init clr-row clr-justify-content-center" style="padding:5%;text-align:center;">
                <div class="clr-col-lg-4 clr-col-12">
                    <img src="../../assets/connect.png" style="width:90%;margin:5%;height:auto;">

                </div>
                <div class="clr-col-lg-7 clr-col-12 clr-align-self-center" style="padding:5%;text-align:center;">
                    <h1 style="font-weight: 500;font-size:50px;">Connect with people who can help</h1>
                    <h3>Be connected with experienced professionals looking to help you advance academically and
                        professionally</h3><br>

                    <button class="btn" style="color:black;border-color:black;" (click)="signup()">Find Help</button>
                </div>
            </div>


            <div animateOnScroll animationName="animated slideInUp"
                class="hide-on-init clr-row clr-justify-content-center"
                style="padding:5%;text-align:center;background-color:white">
                <div class="clr-col-lg-4 clr-col-12">
                    <img src="../../assets/join.png" style="width:90%;margin:5%;height:auto;">

                </div>
                <div class="clr-col-lg-7 clr-col-12 clr-align-self-center" style="padding:5%;text-align:center;">
                    <h1 style="font-weight: 500;font-size:50px;">Join our expanding network of changemakers</h1>

                    <br>
                    <button class="btn" style="color:black;border-color:black;" (click)="signup()">Join</button>
                </div>
            </div>

            <div class="clr-row " style="background-color:#28536B;padding:5%;">
                <div class="clr-col-lg-3 footer-links clr-align-self-center">
                    <img src="../../assets/GY-unity big.png" style="height:auto;width:50%;">


                </div>



                <div class="clr-col-lg-3 footer-links">
                    <h4 style="font-weight:bold;color:white;">Services</h4>
                    <ul style="color:white;list-style-type: none;">
                        <li> <a routerLink="/authentication/sign-up" style="color:white;">Sign Up</a><br></li>
                        <li> <a target="_blank" href="https://unity.gy" style="color:white;">About Us</a><br></li>
                        <li><a target="_blank" href="https://unity.gy" style="color:white;">Donate Now</a><br></li>
                        <li> <a target="_blank" href="https://unity.gy" style="color:white;">Mission and Vision</a></li>
                    </ul>

                </div>
                <div class="clr-col-lg-3 footer-links">
                    <h4 style="font-weight:bold;color:white;"> Contact Us</h4>
                    <ul style="color:white;list-style-type: none;">
                        <li> <span style="color:white;">
                                <clr-icon class='is-solid' shape="phone-handset" style="color:white;"></clr-icon> 592
                                654-2304
                            </span> <br></li>
                        <li> <span style="color:white;">
                                <clr-icon class="is-solid" shape="envelope" style="color:white;"></clr-icon>
                                unitygy592@gmail.com
                            </span></li>

                        <li> <span style="color:white;">
                                <a style="color:white;" href="/privacy-policy">Privacy Policy</a>

                            </span></li>
                    </ul>


                </div>


                <div class="clr-col-lg-3 footer-links">
                    <h4 style="font-weight:bold;color:white;"> Follow Us</h4>
                    <span style="color:white;opacity: 0.9;">Follow us to get updates and announcements</span><br>

                    <div style="width:100%;" class="clr-row clr-justify-content-center">

                        <a href="https://www.facebook.com/unitygy592" target="_blank"
                            style="color:white;font-size:20px;padding:10px;" class="fa fa-facebook"></a>
                        <a style="color:white;font-size:20px;padding:10px;" class="fa fa-instagram"></a>



                    </div>



                </div>


            </div>

            <div class="clr-row clr-justify-content-center" style="background-color:whitesmoke;width:100%;">
                <div class="clr-col" style="text-align: center;">
                    <span style="font-weight: bold;">Unity.GY &#169; 2020 </span>
                </div>

            </div>

        </div>



    </div>
</clr-main-container>