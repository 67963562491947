import { DoneeStateModel } from './donee-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DoneeService } from 'src/app/services/donee.service';

import { GetAllDonee, GetDonee, UpdateDonee, CreateDonee } from './donee-actions';
import { tap } from 'rxjs/operators';

@State<DoneeStateModel>({
    name:'Donee'
  })
@Injectable()
  export class DoneeState{
  
    constructor(private service: DoneeService){
  
    }
  
    @Selector()
    static donees(state:DoneeStateModel){
      return state.donees.filter( object => object.active==true);
    }

    @Selector()
    static donee(state:DoneeStateModel){
      return state.donee;
    }
  
  
    @Action(GetAllDonee)
    getdonees({patchState}:StateContext<DoneeStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          donees: result
        });
      }));
    }

    @Action(GetDonee,  {cancelUncompleted: true })
    getdoneeMethodById({patchState}:StateContext<DoneeStateModel>, {id}: GetDonee){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          donee: result
        });
      }));
    }
  
    @Action(UpdateDonee)
    updatedoneeMethod({dispatch}:StateContext<DoneeStateModel>,{update}:UpdateDonee
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
           
            }
          )
        )
      }

      @Action(CreateDonee)
      deletedonee({dispatch, patchState}:StateContext<DoneeStateModel>,{create}:CreateDonee
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
              
              }
            )
          )
        }
  
  
    
  
  }