export class GetDonorType{
    constructor(public id: number){
    
    }
    
    static readonly type="[DonorType] GetDonorType";
    
    }
    
    export class GetAllDonorType{
        constructor(){
        
        }
        
        static readonly type="[DonorType] GetAllDonorType";
        
        }
    
    export class UpdateDonorType{
        constructor(public update:any){
    
        }
    
        static readonly type="[DonorType] UpdateDonorType";
    }
        
    export class CreateDonorType{
        constructor(public create:any){
    
        }
    
        static readonly type="[DonorType] CreateDonorType";
    }


