export class GetDonee{
    constructor(public id: number){
    
    }
    
    static readonly type="[Donee] GetDonee";
    
    }
    
    export class GetAllDonee{
        constructor(){
        
        }
        
        static readonly type="[Donee] GetAllDonee";
        
        }
    
    export class UpdateDonee{
        constructor(public update:any){
    
        }
    
        static readonly type="[Donee] UpdateDonee";
    }
        
    export class CreateDonee{
        constructor(public create:any){
    
        }
    
        static readonly type="[Donee] CreateDonee";
    }


