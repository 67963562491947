import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticationState } from 'src/app/store/authentication/authentication-state';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {


  @Select(AuthenticationState.authorization) role$: Observable<string>;
  role:string;

  constructor() {
    this.role$.subscribe( role => this.role =role);
   }

  ngOnInit(): void {
  }

}
