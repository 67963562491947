export class GetVillage{
    constructor(public id: number){
    
    }
    
    static readonly type="[Village] GetVillage";
    
    }
    
    export class GetAllVillage{
        constructor(){
        
        }
        
        static readonly type="[Village] GetAllVillage";
        
        }
    
    export class UpdateVillage{
        constructor(public update:any){
    
        }
    
        static readonly type="[Village] UpdateVillage";
    }
        
    export class CreateVillage{
        constructor(public create:any){
    
        }
    
        static readonly type="[Village] CreateVillage";
    }


