import { RegionStateModel } from './region-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RegionService } from 'src/app/services/region.service';

import { GetAllRegion, GetRegion, UpdateRegion, CreateRegion } from './region-actions';
import { tap } from 'rxjs/operators';

@State<RegionStateModel>({
    name:'Region'
  })
@Injectable()
  export class RegionState{
  
    constructor(private service: RegionService){
  
    }
  
    @Selector()
    static regions(state:RegionStateModel){
      return state.regions.filter( object => object.active==true);
    }

    @Selector()
    static regionMethod(state:RegionStateModel){
      return state.region;
    }
  
  
    @Action(GetAllRegion)
    getregions({patchState}:StateContext<RegionStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          regions: result
        });
      }));
    }

    @Action(GetRegion)
    getregionMethodById({patchState}:StateContext<RegionStateModel>, {id}: GetRegion){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          region: result
        });
      }));
    }
  
    @Action(UpdateRegion)
    updateregionMethod({dispatch}:StateContext<RegionStateModel>,{update}:UpdateRegion
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllRegion());
            }
          )
        )
      }

      @Action(CreateRegion)
      deleteregion({dispatch, patchState}:StateContext<RegionStateModel>,{create}:CreateRegion
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllRegion());
              }
            )
          )
        }
  
  
    
  
  }