<clr-main-container>
    <app-header></app-header>
    <div class="content-container" >
        <clr-vertical-nav [clr-nav-level]="2">
            <a clrVerticalNavLink routerLink="/messages/inbox" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="envelope" class="is-solid"></clr-icon>
                Inbox
            </a>
            <a clrVerticalNavLink routerLink="/messages/sent" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="truck" class="is-solid"></clr-icon>
                Sent Mail
            </a>
        </clr-vertical-nav>
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>
