import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { LoginError, Signout } from '../store/authentication/authentication-actions';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            
            if (err.status === 401) {
                this.store.dispatch(Signout);
            }


            if (
                request.url.toLowerCase().includes("authenticate")
            ) {
                console.log(err)
                this.store.dispatch(new LoginError(err.error))

            }

           

            const error = err.message;
            return throwError(error);
        }));
    }
}

