import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-users',
  templateUrl: './verify-users.component.html',
  styleUrls: ['./verify-users.component.scss']
})
export class VerifyUsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
