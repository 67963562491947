import { UserStateModel } from './user-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

import { GetAllUser, GetUser, UpdateUser, CreateUser, GetAdmin, ApproveUser } from './user-actions';
import { tap } from 'rxjs/operators';

@State<UserStateModel>({
    name:'User'
  })
@Injectable()
  export class UserState{
  
    constructor(private service: UserService){
  
    }
  
    @Selector()
    static users(state:UserStateModel){
      return state.users.filter( object => object.active==true).sort(function (a, b) {
        return (+a.approved) - (+ b.approved);
      });
    }

    @Selector()
    static user(state:UserStateModel){
      return state.user;
    }
  
  
    @Action(GetAllUser)
    getusers({patchState}:StateContext<UserStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          users: result
        });
      }));
    }

    @Action(GetUser,  {cancelUncompleted: true })
    getuserById({patchState}:StateContext<UserStateModel>, {id}: GetUser){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          user: result
        });
      }));
    }

    @Action(ApproveUser)
    approve({patchState}:StateContext<UserStateModel>, {id}: ApproveUser){
      return this.service.approve(id).pipe(tap(result => {
       
      }));
    }
  
    @Action(UpdateUser)
    updateuser({dispatch}:StateContext<UserStateModel>,{update}:UpdateUser
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             
            }
          )
        )
      }

      @Action(CreateUser)
      deleteuser({dispatch, patchState}:StateContext<UserStateModel>,{create}:CreateUser
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                
              }
            )
          )
        }


        @Action(GetAdmin)
        getAdmin({dispatch}:StateContext<UserStateModel>
          ){
            
            
          }
  
  
    
  
  }