export class GetIncomeLevel{
    constructor(public id: number){
    
    }
    
    static readonly type="[IncomeLevel] GetIncomeLevel";
    
    }
    
    export class GetAllIncomeLevel{
        constructor(){
        
        }
        
        static readonly type="[IncomeLevel] GetAllIncomeLevel";
        
        }
    
    export class UpdateIncomeLevel{
        constructor(public update:any){
    
        }
    
        static readonly type="[IncomeLevel] UpdateIncomeLevel";
    }
        
    export class CreateIncomeLevel{
        constructor(public create:any){
    
        }
    
        static readonly type="[IncomeLevel] CreateIncomeLevel";
    }


