import { RoleStateModel } from './role-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RoleService } from 'src/app/services/role.service';

import { GetAllRole, GetRole, UpdateRole, CreateRole } from './role-actions';
import { tap } from 'rxjs/operators';

@State<RoleStateModel>({
    name:'Role'
  })
@Injectable()
  export class RoleState{
  
    constructor(private service: RoleService){
  
    }
  
    @Selector()
    static roles(state:RoleStateModel){
      return state.roles.filter( object => object.active==true && object.name !='Admin' && object.name !='DeliveryPerson');
    }

    @Selector()
    static roleMethod(state:RoleStateModel){
      return state.role;
    }
  
  
    @Action(GetAllRole)
    getroles({patchState}:StateContext<RoleStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          roles: result
        });
      }));
    }

    @Action(GetRole)
    getroleMethodById({patchState}:StateContext<RoleStateModel>, {id}: GetRole){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          role: result
        });
      }));
    }
  
    @Action(UpdateRole)
    updateroleMethod({dispatch}:StateContext<RoleStateModel>,{update}:UpdateRole
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllRole());
            }
          )
        )
      }

      @Action(CreateRole)
      deleterole({dispatch, patchState}:StateContext<RoleStateModel>,{create}:CreateRole
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllRole());
              }
            )
          )
        }
  
  
    
  
  }