import { IncomeRangeStateModel } from './income-range-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { IncomeRangeService } from 'src/app/services/income-range.service';

import { GetAllIncomeRange, GetIncomeRange, UpdateIncomeRange, CreateIncomeRange } from './income-range-actions';
import { tap } from 'rxjs/operators';

@State<IncomeRangeStateModel>({
    name:'IncomeRange'
  })
@Injectable()
  export class IncomeRangeState{
  
    constructor(private service: IncomeRangeService){
  
    }
  
    @Selector()
    static incomeRanges(state:IncomeRangeStateModel){
      return state.incomeRanges.filter( object => object.active==true);
    }

    @Selector()
    static incomerangeMethod(state:IncomeRangeStateModel){
      return state.incomerange;
    }
  
  
    @Action(GetAllIncomeRange)
    getincomeRanges({patchState}:StateContext<IncomeRangeStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          incomeRanges: result
        });
      }));
    }

    @Action(GetIncomeRange)
    getincomerangeMethodById({patchState}:StateContext<IncomeRangeStateModel>, {id}: GetIncomeRange){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          incomerange: result
        });
      }));
    }
  
    @Action(UpdateIncomeRange)
    updateincomerangeMethod({dispatch}:StateContext<IncomeRangeStateModel>,{update}:UpdateIncomeRange
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllIncomeRange());
            }
          )
        )
      }

      @Action(CreateIncomeRange)
      deleteincomerange({dispatch, patchState}:StateContext<IncomeRangeStateModel>,{create}:CreateIncomeRange
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllIncomeRange());
              }
            )
          )
        }
  
  
    
  
  }