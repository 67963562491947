import { DonorTypeStateModel } from './donor-type-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DonorTypeService } from 'src/app/services/donor-type.service';

import { GetAllDonorType, GetDonorType, UpdateDonorType, CreateDonorType } from './donor-type-actions';
import { tap } from 'rxjs/operators';

@State<DonorTypeStateModel>({
    name:'DonorType'
  })
@Injectable()
  export class DonorTypeState{
  
    constructor(private service: DonorTypeService){
  
    }
  
    @Selector()
    static donorTypes(state:DonorTypeStateModel){
      return state.donorTypes.filter( object => object.active==true);
    }

    @Selector()
    static donortypeMethod(state:DonorTypeStateModel){
      return state.donortype;
    }
  
  
    @Action(GetAllDonorType)
    getdonorTypes({patchState}:StateContext<DonorTypeStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          donorTypes: result
        });
      }));
    }

    @Action(GetDonorType)
    getdonortypeMethodById({patchState}:StateContext<DonorTypeStateModel>, {id}: GetDonorType){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          donortype: result
        });
      }));
    }
  
    @Action(UpdateDonorType)
    updatedonortypeMethod({dispatch}:StateContext<DonorTypeStateModel>,{update}:UpdateDonorType
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDonorType());
            }
          )
        )
      }

      @Action(CreateDonorType)
      deletedonortype({dispatch, patchState}:StateContext<DonorTypeStateModel>,{create}:CreateDonorType
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDonorType());
              }
            )
          )
        }
  
  
    
  
  }