import { DeliveryStateModel } from './delivery-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetAllDelivery, GetDelivery, UpdateDelivery, CreateDelivery } from './delivery-actions';
import { tap } from 'rxjs/operators';
import { DeliveryService } from 'src/app/services/delivery.service';

@State<DeliveryStateModel>({
    name:'Delivery'
  })
@Injectable()
  export class DeliveryState{
  
    constructor(private service: DeliveryService){
  
    }
  
    @Selector()
    static deliveries(state:DeliveryStateModel){
      return state.deliveries.filter( object => object.active==true);
    }

    @Selector()
    static delivery(state:DeliveryStateModel){
      return state.delivery;
    }
  
  
    @Action(GetAllDelivery)
    getdeliveries({patchState}:StateContext<DeliveryStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          deliveries: result
        });
      }));
    }

    @Action(GetDelivery)
    getDeliveryById({patchState}:StateContext<DeliveryStateModel>, {id}: GetDelivery){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          delivery: result
        });
      }));
    }
  
    @Action(UpdateDelivery)
    updateDelivery({dispatch}:StateContext<DeliveryStateModel>,{update}:UpdateDelivery
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDelivery());
            }
          )
        )
      }

      @Action(CreateDelivery)
      deleteDelivery({dispatch, patchState}:StateContext<DeliveryStateModel>,{create}:CreateDelivery
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDelivery());
              }
            )
          )
        }
  
    }