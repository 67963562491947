export class GetAgeRange{
    constructor(public id: number){
    
    }
    
    static readonly type="[AgeRange] GetAgeRange";
    
    }
    
    export class GetAllAgeRange{
        constructor(){
        
        }
        
        static readonly type="[AgeRange] GetAllAgeRange";
        
        }
    
    export class UpdateAgeRange{
        constructor(public update:any){
    
        }
    
        static readonly type="[AgeRange] UpdateAgeRange";
    }
        
    export class CreateAgeRange{
        constructor(public create:any){
    
        }
    
        static readonly type="[AgeRange] CreateAgeRange";
    }


