import { InterestFieldStateModel } from './interest-field-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { InterestFieldService } from 'src/app/services/interest-field.service';

import { GetAllInterestField, GetInterestField, UpdateInterestField, CreateInterestField } from './interest-field-actions';
import { tap } from 'rxjs/operators';

@State<InterestFieldStateModel>({
    name:'InterestField'
  })
@Injectable()
  export class InterestFieldState{
  
    constructor(private service: InterestFieldService){
  
    }
  
    @Selector()
    static interestFields(state:InterestFieldStateModel){
      return state.interestFields.filter( object => object.active==true).sort(function(a,b){
        if(a.name < b.name){
          return -1;
        }else{
          return 1;
        }
      });
    }

    @Selector()
    static interestfield(state:InterestFieldStateModel){
      return state.interestfield;
    }
  
  
    @Action(GetAllInterestField)
    getinterestFields({patchState}:StateContext<InterestFieldStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          interestFields: result
        });
      }));
    }

    @Action(GetInterestField)
    getinterestfieldMethodById({patchState}:StateContext<InterestFieldStateModel>, {id}: GetInterestField){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          interestfield: result
        });
      }));
    }
  
    @Action(UpdateInterestField)
    updateinterestfieldMethod({dispatch}:StateContext<InterestFieldStateModel>,{update}:UpdateInterestField
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllInterestField());
            }
          )
        )
      }

      @Action(CreateInterestField)
      deleteinterestfield({dispatch, patchState}:StateContext<InterestFieldStateModel>,{create}:CreateInterestField
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllInterestField());
              }
            )
          )
        }
  
  
    
  
  }