<clr-main-container>
    <app-header></app-header>
    <div class="content-container">
        <clr-vertical-nav [clr-nav-level]="2">
            <a clrVerticalNavLink routerLink="/leaderboard" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="user" class="is-solid"></clr-icon>
               Leaderboard
            </a>
            <a clrVerticalNavLink routerLink="/badges" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="crown" class="is-solid"></clr-icon>
                Badges
            </a>
        </clr-vertical-nav>
        <div class="content-area" style="background-image: url('../../assets/leaderboard.png');background-repeat: no-repeat;background-size:auto;background-position: center;">
            <h1 style="font-weight:bolder;" >Leaderboard</h1>
            <p>These are the top donors on the platform who have shown an unwavering dedication to helping youth across Guyana.
                Unity.GY is proud of all its contributors and hopes to see new faces rising on the leaderboard every day.
            </p>

            <span class="spinner" *ngIf="!pageLoaded || !(leaderboard$ | async)">
                Loading...
            </span>

            <clr-datagrid *ngIf="pageLoaded">
            
                <clr-dg-row *clrDgItems="let user of leaderboard; let i = index"  >

                    <clr-dg-cell style="text-align: left;width:60%;">
                        <table style="padding-left:8%" class="table-stuff">
                            <tr>
                                <td style="width:40px;" class="pos-row">  <span style="margin:10px;font-weight:bold;font-size:30px;color:black;" class="pos-number">{{i+1}}</span> </td>
                                <td>  <img *ngIf="!user.profilePicture" src="../../assets/blank_profile.webp" class="person-pic" style="width:100px; height:100px;border-radius:50%;margin:10px;" alt=""><img *ngIf="user.profilePicture" class="person-pic" src="{{user.profilePicture}}"style="width:100px; height:100px;border-radius:50%;margin:10px;" alt=""></td>
                                <td> <span style="font-weight:bold;font-size:20px;color:black;text-align:left !important;" class="name-words"> {{user.firstName}} <br style="display:none;" class="word-break"> {{user.lastName}} </span></td>
                            </tr>
                        </table>
                        
                   
                       
                    </clr-dg-cell>
                   
                    <clr-dg-cell style="width:40%;">
           
                                               <table style="height:100%" >
                                                <tr >
                                                    <td style="text-align: left;"> <div>
                                                        
                                                        <img src="{{user.badge}}" class="board-badge" style="height:55px;width:55px;">
                                                          </div>
                                                         </td> 

                                                         
                                                          <td style="text-align: left;height:100%"> 
                                                            <div class="clr-row clr-align-items-center" style="height:100%;">
                                                                <div class="clr-col">
                                                                    <span> <span style="font-weight:bolder;font-size:20px;" class="rank-words">{{user.rank| uppercase}}</span><br class="rank-words">
                                                                    <span *ngIf="!user.totalPoints" class="points-words">0  points</span> <span *ngIf="user.totalPoints" class="points-words"> {{user.totalPoints}} points</span>
                                                                  </span>
                                                                </div>
                                                             </div>
                                                          </td>
                                                  
                                                
                                                </tr>
                                            </table> 
                                                
                    </clr-dg-cell>
                </clr-dg-row>

        
            </clr-datagrid>
        </div>
    </div>
</clr-main-container>