export class GetDifficulty{
    constructor(public id: number){
    
    }
    
    static readonly type="[Difficulty] GetDifficulty";
    
    }
    
    export class GetAllDifficulty{
        constructor(){
        
        }
        
        static readonly type="[Difficulty] GetAllDifficulty";
        
        }
    
    export class UpdateDifficulty{
        constructor(public update:any){
    
        }
    
        static readonly type="[Difficulty] UpdateDifficulty";
    }
        
    export class CreateDifficulty{
        constructor(public create:any){
    
        }
    
        static readonly type="[Difficulty] CreateDifficulty";
    }


