import { VillageStateModel } from './village-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { GetAllVillage, GetVillage, UpdateVillage, CreateVillage } from './village-actions';
import { tap } from 'rxjs/operators';
import { VillageService } from 'src/app/services/village .service';

@State<VillageStateModel>({
    name:'Village'
  })
@Injectable()
  export class VillageState{
  
    constructor(private service: VillageService){
  
    }
  
    @Selector()
    static villages(state:VillageStateModel){
      return state.villages.filter( object => object.active==true).sort(function(a,b){
        if(a.name < b.name){
          return -1;
        }else{
          return 1;
        }
      });;
    }

    @Selector()
    static villageMethod(state:VillageStateModel){
      return state.village;
    }
  
  
    @Action(GetAllVillage)
    getvillages({patchState}:StateContext<VillageStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          villages: result
        });
      }));
    }

    @Action(GetVillage)
    getvillageMethodById({patchState}:StateContext<VillageStateModel>, {id}: GetVillage){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          village: result
        });
      }));
    }
  
    @Action(UpdateVillage)
    updatevillageMethod({dispatch}:StateContext<VillageStateModel>,{update}:UpdateVillage
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllVillage());
            }
          )
        )
      }

      @Action(CreateVillage)
      deletevillage({dispatch, patchState}:StateContext<VillageStateModel>,{create}:CreateVillage
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllVillage());
              }
            )
          )
        }
  
  
    
  
  }