import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Signin, PersistLocal, Signout, VerifyCode, ResetPassword, CheckUsername, CheckEmail, ResendVerify, LoginError } from './authentication-actions';
import { catchError, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { AuthenticationStateModel } from './authentication-state-model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GetDonor } from '../donor/donor-actions';
import { GetDonee } from '../donee/donee-actions';
import { GetDeliveryPerson } from '../delivery-person/delivery-person-actions';
import { GetAdmin, GetUser } from '../user/user-actions';

@State<AuthenticationStateModel>({
    name: 'authentication' 
  })
  @Injectable()
  export class AuthenticationState {

    @Selector()
    static token(state: AuthenticationStateModel) {
      return state.token;
    }

    @Selector()
    static loginError(state: AuthenticationStateModel) {
      return state.loginMessage;
    }
  
  
    @Selector()
    static id(state: AuthenticationStateModel) {
      return state.id;
    }
  
    @Selector()
    static authorization(state: AuthenticationStateModel) {
      return state.authorization;
    }

    constructor(private service: AuthenticationService) {

    }

    
  @Action(Signin, { cancelUncompleted: true })
  login(
    { patchState, dispatch, getState}: StateContext<AuthenticationStateModel>,
    {payload}: Signin
  ) {
    return this.service.signin(payload).pipe(
  
      tap(result => {

        if(result){
        let decoded=jwt_decode(result.token);

        patchState({
          token: result.token, 
          id:parseInt(decoded['nameid'],10),
          authorization: decoded['role']
        });

        dispatch(new GetUser(parseInt(decoded['nameid'],10)));


        if(decoded['role']=="Donor"){
          dispatch(new GetDonor(parseInt(decoded['nameid'],10)));

        }else if(decoded['role']=="Donee"){
          dispatch(new GetDonee(parseInt(decoded['nameid'],10)));

        }else if(decoded['role']=="DeliveryPerson"){
          dispatch(new GetDeliveryPerson(parseInt(decoded['nameid'],10)));

        }else if(decoded['role']=="Admin"){
          dispatch(new GetAdmin());
        }

      }
      }),
    );
  }


  @Action(LoginError,  { cancelUncompleted: true })
  error({patchState}:StateContext<AuthenticationStateModel>,{string}:LoginError){
   patchState({
     loginMessage:string
   })
  }





  @Action(PersistLocal,  { cancelUncompleted: true })
  persist(
    {patchState}: StateContext<AuthenticationStateModel>,
    {payload}: any
  ){
    patchState({
      token: payload.token,
      id: payload.id, 
      authorization: payload.authortization
    });
  }

  @Action(Signout, { cancelUncompleted: true })
  logout({ setState, getState,dispatch }: StateContext<AuthenticationStateModel>) {
    return this.service.signout().pipe(
      tap(()=> {
        
      })


    );


  }

  @Action(VerifyCode,  { cancelUncompleted: true })
  verifyCode({patchState}:StateContext<AuthenticationStateModel>,{payload}:VerifyCode){
    return this.service.verifyCode(payload).pipe(tap(
      result => {

      }));
  }

  @Action(CheckEmail,  { cancelUncompleted: true })
  checkEmail({patchState}:StateContext<AuthenticationStateModel>,{email}:CheckEmail){
    return this.service.checkEmail(email).pipe(tap(
      result => {

      }));
  }

  @Action(CheckUsername,  { cancelUncompleted: true })
  checkUsername({patchState}:StateContext<AuthenticationStateModel>,{username}:CheckUsername){
    return this.service.checkUsername(username).pipe(tap(
      result => {

      }));
  }

  @Action(ResetPassword, { cancelUncompleted: true })
ResetPassword({dispatch}:StateContext<AuthenticationStateModel>,{payload}:ResetPassword){
  return this.service.changePassword(payload).pipe(
    tap(
    result =>{
      
    }
  ))
}

@Action(ResendVerify, { cancelUncompleted: true })
resend({dispatch}:StateContext<AuthenticationStateModel>,{email}:ResendVerify){
  return this.service.resendVerify(email).pipe(
    tap(
    result =>{
      
    }
  ))
}

  
  }