<clr-main-container>
    <div class="content-container">

        <div class="content-area" style="padding:1%;">
            <pre>
                <h1>PRIVACY NOTICE</h1><h6>Last updated June 08, 2022</h6>
                This privacy notice for Unity Guyana ('Company', 'we', 'us', or 'our',), describes how and why we might collect,
                store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:Visit
                our website at https://www.unitygy.com, or any website of ours that links to this privacy noticeEngage with us in
                other related ways, including any sales, marketing, or eventsQuestions or concerns? Reading this privacy notice will
                help you understand your privacy rights and choices.
                If you do not agree with our policies and practices, please do not use our Services.
                If you still have any questions or concerns, please contact us at unitygy592@gmail.com.
            
                <h3>SUMMARY OF KEY POINTS</h3>
                This summary provides key points from our privacy notice. 
                What personal information do we process? When you visit, use, or navigate our Services, we may process personal
                information depending on how you interact with Unity Guyana and the Services, the choices you make, and the products and
                features you use.
                
                Do we process any sensitive personal information? We may process sensitive personal information when necessary with your
                consent or as otherwise permitted by applicable law. 
                
                Do we receive any information from third parties? We do not receive any information from third parties. 
                
                How do we process your information? We process your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent. We process your information only when we have a valid
                legal reason to do so.
                
                In what situations and with which parties do we share personal information? We may share information in
                specific situations and with specific third parties.
                
                How do we keep your information safe? We have organisational and technical processes and procedures in
                place to protect your personal information. However, no electronic transmission over the internet or
                information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee
                that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your information.
                
                What are your rights? Depending on where you are located geographically, the applicable privacy law
                may mean you have certain rights regarding your personal information.
                
                How do you exercise your rights? The easiest way to exercise your rights is by filling out our
                data subject request form available here: unitygy592@gmail.com, or by contacting us. We will
                consider and act upon any request in accordance with applicable data protection laws.
                
                1. WHAT INFORMATION DO WE COLLECT?
                Personal information you disclose to us
                In Short: We collect personal information that you provide to us.We collect personal information
                that you voluntarily provide to us when you register on the Services, express an interest in
                obtaining information about us or our products and Services, when you participate in activities
                on the Services, or otherwise when you contact us.Personal Information Provided by You. The
                personal information that we collect depends on the context of your interactions with us and the
                Services, the choices you make, and the products and features you use. The personal information
                we collect may include the following:namesphone numbersemail addressesusernamespasswordscontact
                or authentication dataSensitive Information. When necessary, with your consent or as otherwise
                permitted by applicable law, we process the following categories of sensitive
                information:information revealing race or ethnic originSocial Media Login Data. We may provide
                you with the option to register with us using your existing social media account details, like
                your Facebook, Twitter, or other social media account. If you choose to register in this way, we
                will collect the information described in the section called 'HOW DO WE HANDLE YOUR SOCIAL
                LOGINS?' below.All personal information that you provide to us must be true, complete, and
                accurate, and you must notify us of any changes to such personal information.
                
                2. HOW DO WE PROCESS YOUR INFORMATION?
                In Short: We process your information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to comply with law. We may also
                process your information for other purposes with your consent.We process your personal
                information for a variety of reasons, depending on how you interact with our Services,
                including:To facilitate account creation and authentication and otherwise manage user accounts.
                We may process your information so you can create and log in to your account, as well as keep
                your account in working order.To enable user-to-user communications. We may process your
                information if you choose to use any of our offerings that allow for communication with another
                user.
                
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                In Short: We may share information in specific situations described in this section and/or with
                the following third parties.We may need to share your personal information in the following
                situations:Business Transfers. We may share or transfer your information in connection with, or
                during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                a portion of our business to another company.Other Users. When you share personal information
                (for example, by posting comments, contributions, or other content to the Services) or otherwise
                interact with public areas of the Services, such personal information may be viewed by all users
                and may be publicly made available outside the Services in perpetuity. If you interact with
                other users of our Services and register for our Services through a social network (such as
                Facebook), your contacts on the social network will see your name, profile photo, and
                descriptions of your activity. Similarly, other users will be able to view descriptions of your
                activity, communicate with you within our Services, and view your profile.
                
                4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                In Short: If you choose to register or log in to our services using a social media account, we
                may have access to certain information about you.Our Services offer you the ability to register
                and log in using your third-party social media account details (like your Facebook or Twitter
                logins). Where you choose to do this, we will receive certain profile information about you from
                your social media provider. The profile information we receive may vary depending on the social
                media provider concerned, but will often include your name, email address, friends list, and
                profile picture, as well as other information you choose to make public on such a social media
                platform.We will use the information we receive only for the purposes that are described in this
                privacy notice or that are otherwise made clear to you on the relevant Services. Please note
                that we do not control, and are not responsible for, other uses of your personal information by
                your third-party social media provider. We recommend that you review their privacy notice to
                understand how they collect, use, and share your personal information, and how you can set your
                privacy preferences on their sites and apps.
                
                5. HOW LONG DO WE KEEP YOUR INFORMATION?
                In Short: We keep your information for as long as necessary to fulfil the purposes outlined in
                this privacy notice unless otherwise required by law. We will only keep your personal
                information for as long as it is necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law (such as tax, accounting, or
                other legal requirements). No purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users have an account with us.When we
                have no ongoing legitimate business need to process your personal information, we will either
                delete or anonymise such information, or, if this is not possible (for example, because your
                personal information has been stored in backup archives), then we will securely store your
                personal information and isolate it from any further processing until deletion is possible.
                
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                In Short: We aim to protect your personal information through a system of organisational and
                technical security measures.We have implemented appropriate and reasonable technical and
                organisational security measures designed to protect the security of any personal information we
                process. However, despite our safeguards and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised
                third parties will not be able to defeat our security and improperly collect, access, steal, or
                modify your information. Although we will do our best to protect your personal information,
                transmission of personal information to and from our Services is at your own risk. You should
                only access the Services within a secure environment.
                
                7. WHAT ARE YOUR PRIVACY RIGHTS?
                In Short: You may review, change, or terminate your account at any time.If you are located in
                the EEA or UK and you believe we are unlawfully processing your personal information, you also
                have the right to complain to your local data protection supervisory authority. You can find
                their contact details here:
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm. If you are located
                in Switzerland, the contact details for the data protection authorities are available here:
                https://www.edoeb.admin.ch/edoeb/en/home.html. Withdrawing your consent: If we are relying on
                your consent to process your personal information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw your consent at any time. You
                can withdraw your consent at any time by contacting us by using the contact details provided in
                the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. However, please note that this
                will not affect the lawfulness of the processing before its withdrawal, nor when applicable law
                allows, will it affect the processing of your personal information conducted in reliance on
                lawful processing grounds other than consent.Account InformationIf you would at any time like to
                review or change the information in your account or terminate your account, you can:Log in to
                your account settings and update your user account.Contact us using the contact information
                provided.Upon your request to terminate your account, we will deactivate or delete your account
                and information from our active databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms
                and/or comply with applicable legal requirements.If you have questions or comments about your
                privacy rights, you may email us at unitygy592@gmail.com.
                
                8. CONTROLS FOR DO-NOT-TRACK FEATURES
                Most web browsers and some mobile operating systems and mobile applications include a
                Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not
                to have data about your online browsing activities monitored and collected. At this stage no
                uniform technology standard for recognising and implementing DNT signals has been finalised. As
                such, we do not currently respond to DNT browser signals or any other mechanism that
                automatically communicates your choice not to be tracked online. If a standard for online
                tracking is adopted that we must follow in the future, we will inform you about that practice in
                a revised version of this privacy notice.
                
                9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                In Short: Yes, if you are a resident of California, you are granted specific rights regarding
                access to your personal information.California Civil Code Section 1798.83, also known as the
                'Shine The Light' law, permits our users who are California residents to request and obtain from
                us, once a year and free of charge, information about categories of personal information (if
                any) we disclosed to third parties for direct marketing purposes and the names and addresses of
                all third parties with which we shared personal information in the immediately preceding
                calendar year. If you are a California resident and would like to make such a request, please
                submit your request in writing to us using the contact information provided below.If you are
                under 18 years of age, reside in California, and have a registered account with Services, you
                have the right to request removal of unwanted data that you publicly post on the Services. To
                request removal of such data, please contact us using the contact information provided below and
                include the email address associated with your account and a statement that you reside in
                California. We will make sure the data is not publicly displayed on the Services, but please be
                aware that the data may not be completely or comprehensively removed from all our systems (e.g.
                backups, etc.).
                
                10. DO WE MAKE UPDATES TO THIS NOTICE?
                In Short: Yes, we will update this notice
                as necessary to stay compliant with relevant laws.We may update this privacy notice from time to
                time. The updated version will be indicated by an updated 'Revised' date and the updated version
                will be effective as soon as it is accessible. If we make material changes to this privacy
                notice, we may notify you either by prominently posting a notice of such changes or by directly
                sending you a notification. We encourage you to review this privacy notice frequently to be
                informed of how we are protecting your information.
                
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                If you have questions or comments about this notice, you may email us at unitygy592@gmail.com or by post to:
                Unity Guyana
                1377 Section A Block X
                Great Diamond
                East Bank Demerara 
                Guyana
                
                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT ROM YOU?
                Based on the applicable laws of your country, you may have the right to request access
                to the personal information we collect from you, change that information, or delete it. To
                request to review, update, or delete your personal information, please visit:
                unitygy592@gmail.com.
            </pre>
        </div>

    </div>

</clr-main-container>